import { OmniDatePicker } from '../../Shared/Controls/DatePicker/OmniDatePicker';
import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
export const OverlayDrawerDate = (props) => {
    return (
        <OverlayDrawer position="end" open={props.showAttachDatePanel} modalType="alert"
                style={{ width: '448px' }}>
                <DrawerHeader>
                    <DrawerHeaderTitle>
                        Seleccione las fechas
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className='row'>
                        <div className='col-md-6 mt-4'>
                            <OmniDatePicker title='Desde el'
                                property="startDate"
                            edit={props.setParam}
                            value={props.param?.startDate} inactivefieldEdited
                            maxDate={new Date()} initialPickerDate={props.param?.startDate} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 mt-4'>
                            <OmniDatePicker title='hasta el'
                                property="endDate"
                            edit={props.setParam}
                            value={props.param?.endDate} inactivefieldEdited
                            maxDate={new Date()} initialPickerDate={props.param?.endDate} />
                        </div>
                    </div>
                </DrawerBody>
                <DrawerFooter >
                    <div className="drawer-footer-container">
                        <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                            icon={<Image src={returnWhite} alt="return" />}
                            iconPosition="after"
                            shape="square" size="small"
                            onClick={() => {
                                props.setShowAttachDatePanel(false);
                            }}
                        >Cerrar</CompoundButton>
                    </div>
                </DrawerFooter>
            </OverlayDrawer>
    )
}
import { useForm } from "react-hook-form";
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Pivot, PivotItem } from "@fluentui/react";
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { useState, useEffect } from 'react';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Suppliers/Edit';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import Detail from './Detail';
import { Add as AddItem } from '../../Shared/ItemList/Add';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniDropdown } from '../../Shared/Controls/Dropdown/OmniDropdown';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import { ItemCost } from '../../../Assets/Constants/Enums/NetCost';
import { ItemPayConditions } from '../../../Assets/Constants/Enums/PayConditions';
import { RfcRegex } from '../../../Assets/Constants/Rfc/RfcRegex';
import { UpdateAsync, GetCalculationMethodAsync, GetCreditChargeTypeAsync, SetAxiosPrivate, GetByIdAsync, GetLastAccountingAccountAsync } from '../../../Functions/Catalogs/Suppliers/SupplierStore';
import { GetItemsSuppliers, SetAxiosPrivate as SetAxiosPrivateItems, generateObject } from '../.././../Functions/Catalogs/Items/ItemStore';
import { AccountingAccount } from "../../Shared/AccountingAccount/AccountingAccount";


export default function Edit() {
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateItems(axiosPrivate);
    const changeComponent = useSetStatesContext();
    const [openCallout, setOpenCallout] = useState();
    const { setListMessageCatalog, updateItem, useForeignKey, setLoadingSkeleton } = useGeneralStatesContext();
    const { extendedAccounting, baseSuppliers } = useAccountingConfigurationContext();
    const { handleSubmit } = useForm();
    const [autoincrement, setAutoincrement] = useState();
    const [supplier, setSupplier] = useState();
    const [itemList, setItemList] = useState();
    const [listCalculationMethod, setListCalculationMethod] = useState();
    const [listPayType, setListPayType] = useState();
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        supplier: undefined
    });

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setSupplier({ ...result.data, listSupplierItem: generateObject(result.data?.listSupplierItemResult) });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }


    const onBlurName = (event) => {
        ValidText(event, "id", "Clave SAP");
    };
    const onBlurBusinessName = (event) => {
        ValidText(event, "businessName", "razón social");
    };
    const onBlurRfc = (event) => {
        let rfcText = event.target.value;
        if (RfcRegex.test(rfcText) || rfcText === '')
        {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                rfc: undefined
            }));
            setSupplier(prevEedit => ({ ...prevEedit, rfc: rfcText }));
        }
        else
        {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                rfc: 'El rfc no es válido'
            }));
            event?.target?.focus();
        }
    };
    const onBlurAccountingAccount = (event) => {
        ValidText(event, "accountingAccount", "cuenta contable");
    };
    const OnLoadGetItems = async () =>
    {
        let result = await GetItemsSuppliers();
        if (result.isSuccessful)
        {
            setItemList(result.data);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }

    function ValidText(event, property, fild) {
        let text = event.target.value;
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }


    const submit = async () => {
        setOpenCallout(true);
    };

    const AddConfirm = async () =>
    {
        let errorMessages = [];
        if (!supplier.accountingAccount && extendedAccounting) {
            errorMessages.push("La cuenta contable es obligatoria");
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            if (supplier.payConditions != 'C') {
                supplier.creditDays = '0';
                supplier.creditLimit = '0.00';
                supplier.soonPayDays = '0';
            }
            if (!supplier.inTruck) {
                supplier.searchDescription = false;
                supplier.piecesOnly = false;
                supplier.calculateRefill = false;
                supplier.mostSuggestedPurchase = false;
                supplier.addProducts = false;
                supplier.listSupplierItem = [];
            }
            supplier.netCost = supplier.netCost === '0' ? false : true;
            supplier.listSupplierItem = supplier.listSupplierItem.map(item => {
                return { id: (item.itemSku==undefined? 0 :item.id), itemSku: (item.itemSku==undefined? item.id :item.itemSku), description: item.description };
            });
            var data = { ...supplier };
            data.UseAccounting = extendedAccounting;
            if (extendedAccounting)
            {
                data.accountingAccount = baseSuppliers + (supplier.accountingAccount.endsWith(".") ? supplier.accountingAccount.slice(0, -1) : supplier.accountingAccount);
            }
            let result = await UpdateAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                updateItem({ ...data, acronym: localStorage.getItem("userName"), id:id , name:data.businessName});
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    }

    const OnLoadDescripcion = async (url, set) => {
        let result = await url();
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.clue ?? item.id,
                value: item.id,
                text: item.description
            }));
            set(resultList)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadAccountingAccountAutoincrement = async () => {
        let result = await GetLastAccountingAccountAsync();
        if (result.isSuccessful) {
            setAutoincrement(result.data.accountingAccount.toString());
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        try {
            OnLoadDescripcion(GetCalculationMethodAsync, setListCalculationMethod);
            OnLoadDescripcion(GetCreditChargeTypeAsync, setListPayType);
            OnLoadGetItems();
            getById();       
            if (extendedAccounting) {
                OnLoadAccountingAccountAutoincrement();
            }
        } catch (error) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
        }
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons rightButtons={rightButtons}
                    leftButtons={leftButtons} />
                    {
                        supplier!=undefined && supplier &&
                        (
                            <Pivot className="pivot-center-client" overflowBehavior="menu">
                            { /* INFORMACIÓN */}
                            <PivotItem headerText="Información">
                                <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                            <OmniCard title='Datos generales'>
                                                <div className='row'>
                                                    {
                                                        useForeignKey &&
                                                        <div className='col-lg-6 col-md-12'>
                                                                <OmniTextBox required focus
                                                                    inputType='number'
                                                                    maxLength={10}
                                                                    title='Clave SAP'                                                            
                                                                    property="id"
                                                                    edit={setSupplier}
                                                                    validationMessage={showErrors.name}
                                                                    blur={onBlurName}
                                                                    value={supplier?.id}
                                                                    />
                                                        </div>
                                                    }
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            maxLength={13}
                                                            title="R.F.C."
                                                            focus={useForeignKey ? false : true}                                                     
                                                            property="rfc"
                                                            edit={setSupplier}
                                                            value={supplier?.rfc}
                                                            blur={onBlurRfc} 
                                                            validationMessage={showErrors.rfc}
                                                            />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox required
                                                            title='Razón social'
                                                            maxLength={128}
                                                            property="businessName"
                                                            edit={setSupplier}
                                                            value={supplier?.businessName}
                                                            blur={onBlurBusinessName}
                                                            validationMessage={showErrors.businessName}
                                                            />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox
                                                            title='Nombre comercial'
                                                            maxLength={124}
                                                            property="tradename"
                                                            edit={setSupplier}
                                                            value={supplier?.tradename}
                                                        />
                                                    </div>
                                                </div>     
                                            </OmniCard>
                                        </div>
        
                                        <div className='col-md-auto'>
                                            <OmniCard title='Particulares'>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniLabel text='Saldo' value={ '$ 0.00'} />
                                                    </div>
                                                </div>
                                            </OmniCard>
                                            <OmniCard title='Observaciones'>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox
                                                            title='Observaciones del proveedor'
                                                            maxLength={1024}
                                                            property="observation"
                                                            edit={setSupplier}
                                                            value={supplier?.observation}
                                                        />
                                                    </div>
                                                </div>
                                            </OmniCard>
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                            { /* DATOS FISCALES */}
                            <PivotItem headerText="Datos fiscales">
                                <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                             <OmniCard title='Dirección de facturación'>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType='number'
                                                            title='Código Postal'
                                                            maxLength={8}
                                                            property="postalCode"
                                                            edit={setSupplier}
                                                            value={supplier?.postalCode}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox title="País" value='México' disabled />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            title="Estado"
                                                            maxLength={64}
                                                            property="state"
                                                            edit={setSupplier}
                                                            value={supplier?.state}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox
                                                            title="Municipio"
                                                            maxLength={64}
                                                            property="municipality"
                                                            edit={setSupplier}
                                                            value={supplier?.municipality}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox
                                                            title="Colonia"
                                                            maxLength={32}
                                                            property="colony"
                                                            edit={setSupplier}
                                                            value={supplier?.colony}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox
                                                            title="Localidad"
                                                            maxLength={64}
                                                            property="location"
                                                            edit={setSupplier}
                                                            value={supplier?.location}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox
                                                            title="Calle"
                                                            maxLength={64}
                                                            property="address"
                                                            edit={setSupplier}
                                                            value={supplier?.address}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            title="Número exterior"
                                                            maxLength={8}
                                                            property="outdoorNumber"
                                                            edit={setSupplier}
                                                            value={supplier?.outdoorNumber}
                                                        />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            title="Número interior"
                                                            maxLength={8}
                                                            property="interiorNumber"
                                                            edit={setSupplier}
                                                            value={supplier?.interiorNumber}
                                                        />
                                                    </div>
                                                </div>
                                            </OmniCard>
                                        </div>
                                        <div className='col-md-auto'>
                                            <OmniCard title='Especificaciones para compras'>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniDropdown required
                                                            title='Método de cálculo'
                                                            items={listCalculationMethod}
                                                            property="calculationMethodId"
                                                            name="calculationMethod"
                                                            edit={setSupplier}
                                                            value={supplier?.calculationMethodDescription}
                                                            selected={supplier?.calculationMethodId}
                                                            validationMessage={showErrors.shippingAddress} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniCheckBox
                                                            title='Desglosa impuestos' 
                                                            propertyId="breakDownTaxes"
                                                            edit={setSupplier}
                                                            propertyChecked={supplier?.breakDownTaxes} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniCheckBox
                                                            title='Utiliza costo con nota de crédito en compras'
                                                            propertyId="useCostWithNC"
                                                            edit={setSupplier}
                                                            propertyChecked={supplier?.useCostWithNC} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniCheckBox
                                                            title='Factura con impuestos (USA Tax)'
                                                            propertyId="invoiceWithTax"
                                                            edit={setSupplier}
                                                            propertyChecked={supplier?.invoiceWithTax} />
                                                    </div>
                                                </div>
                                            </OmniCard>
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                            { /* CONTACTO */}
                            <PivotItem headerText="Contacto" headerButtonProps={{
                                'disabled': true,
                                'style': { color: 'grey' }
                            }}></PivotItem>
                            { /* CRÉDITO */}
                            <PivotItem headerText="Crédito">
                                <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                            <OmniCard title='Datos crediticios'>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniRadioGroup
                                                            name="payConditions"
                                                            title="Condiciones de pago"
                                                            orientation="horizontal"
                                                            items={ItemPayConditions}
                                                            default={supplier.payConditions}
                                                            edit={setSupplier}
                                                            propertyId="payConditions"
                                                            />
                                                    </div>
                                                </div>
                                                {
                                                    supplier.payConditions == 'C' &&
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-lg-6 col-md-12'>
                                                            <OmniTextBox
                                                                    inputType="number"
                                                                    title="Días de crédito"
                                                                    maxLength={4}
                                                                    property="creditDays"
                                                                    edit={setSupplier}
                                                                    value={supplier.creditDays}
                                                                />
                                                            </div>
                                                            <div className='col-lg-6 col-md-12'>
                                                                <OmniTextBox
                                                                    inputType={'decimal'}
                                                                    decimals={2}
                                                                    title="Límite de crédito" 
                                                                    maxLength={8}
                                                                    property="creditLimit"
                                                                    edit={setSupplier}
                                                                    value={supplier.creditLimit}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-lg-6 col-md-12'>
                                                                <OmniTextBox
                                                                    inputType="number"
                                                                    title="Días para pronto pago"
                                                                    maxLength={4}
                                                                    property="soonPayDays"
                                                                    edit={setSupplier}
                                                                    value={supplier.soonPayDays}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniDropdown required
                                                            title='Pago preferente'
                                                            items={listPayType}
                                                            property="payTypeId" name="payType"
                                                            edit={setSupplier}
                                                            value={supplier?.payTypeDescription}
                                                            selected={supplier?.payTypeId} />
                                                        
                                                    </div>
                                                </div>
                                            </OmniCard>
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                            { /* REABASTO */}
                            <PivotItem headerText="Reabasto">
                                <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                            <OmniCard title='Información del reabasto'>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            title="Días de entrega"
                                                            maxLength={3}
                                                            property="deliveryDays"
                                                            edit={setSupplier}
                                                            value={supplier?.deliveryDays}
                                                        />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            title="Días de inventario"
                                                            maxLength={2}
                                                            property="inventoryDays"
                                                            edit={setSupplier}
                                                            value={supplier?.inventoryDays}
                                                        />
                                                    </div>    
                                                </div>
                                            </OmniCard>
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                            { /* ADICIONALES */}
                            <PivotItem headerText="Adicionales">   
                                <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                            <OmniCard title='Información del reabasto'>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniCheckBox
                                                            title="Muestra precio con impuestos en reportes"
                                                            propertyId="showsPurchasesWithTaxes"
                                                            edit={setSupplier}
                                                            propertyChecked={supplier?.showsPurchasesWithTaxes} />
                                                    </div>
                                                </div>
                                            </OmniCard>
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                            { /* Proveedor a pie de camión */}
                            <PivotItem headerText="Proveedor a pie de camión">
                                <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniCard title='Proveedor a pie de camión'>
                                                <div className='row'>
                                                    <OmniCheckBox
                                                        title='Este proveedor entrega a pie de camión'
                                                        propertyId="inTruck"
                                                        edit={setSupplier}
                                                        propertyChecked={supplier.inTruck} />
                                                </div>
                                            </OmniCard>
                                            <OmniCard title='Compras a pie de camión'>
                                                {supplier.inTruck &&
                                                    <>
                                                        <div className='row'>
                                                            <OmniCheckBox
                                                                title='Permite búsqueda por descripción'
                                                                propertyId="searchDescription"
                                                                edit={setSupplier}
                                                                propertyChecked={supplier?.searchDescription} />
                                                        </div>
                                                        <div className='row'>
                                                            <OmniSwitch
                                                                start
                                                                label={"Solo usa piezas"}
                                                                edit={setSupplier}
                                                            propertyId="piecesOnly"
                                                            checked={supplier?.piecesOnly}
                                                            />
                                                        </div>
                                                        <div className='row'>
                                                            <OmniCheckBox
                                                                title='Calcula el resurtido'
                                                                propertyId="calculateRefill"
                                                                edit={setSupplier}
                                                                propertyChecked={supplier?.calculateRefill} />
                                                        </div>
                                                    </>
                                                }
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniRadioGroup
                                                            name="netCost"
                                                            title="Captura de costos"
                                                            orientation="horizontal"
                                                            items={ItemCost}
                                                            default={supplier.netCost}
                                                            propertyId="netCost"
                                                            edit={setSupplier}                                                            
                                                        />
                                                    </div>
                                                </div>
                                                {supplier.inTruck &&
                                                    <>
                                                        <div className='row'>
                                                            <OmniCheckBox
                                                                title='Permite aumentar la cantidad de artículos'
                                                                propertyId="mostSuggestedPurchase"
                                                                edit={setSupplier}
                                                                propertyChecked={supplier?.mostSuggestedPurchase} />
                                                        </div>
                                                        <div className='row'>
                                                            <OmniCheckBox
                                                                title='Permite agregar artículos no sugerido'
                                                                propertyId="addProducts"
                                                                edit={setSupplier}
                                                                propertyChecked={supplier?.addProducts} />
                                                        </div>
                                                    </>
                                                }
                                            </OmniCard>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            {supplier.inTruck &&
                                                <OmniCard title='Artículos relacionados'>
                                                    <div className='row'>
                                                        <AddItem description={supplier}
                                                            setDescription={setSupplier}
                                                            itemList={itemList}
                                                            setItemList={setItemList}
                                                            isItemDescriptionList={true}
                                                            useTooltip={true}
                                                            titleColum="Lista de artículos" property="listSupplierItem"
                                                            titleComboBox="Artículos que se puede comprar a este proveedor" />
                                                    </div>
                                                </OmniCard>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                            {
                                extendedAccounting &&
                                <PivotItem headerText="Contabilidad">
                                        <AccountingAccount
                                            edit={setSupplier}
                                            value={supplier?.accountingAccount}
                                            blur={onBlurAccountingAccount}
                                            validationMessage={showErrors.accountingAccount}
                                            baseValue={baseSuppliers}
                                        placeholder={autoincrement}
                                    />
                                </PivotItem>
                            }
                        </Pivot>
                        )
                    }
            </form>
		
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rigthCommandBar'}
                />
            }
        </>
    )
}


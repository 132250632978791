import axios from 'axios';
////Test Azure
//const BASE_URL = 'https://devomni.azurewebsites.net'
////dev
//const BASE_URL = 'http://192.168.1.3:8091';
//const BASE_URL = 'http://192.168.1.3:8082';
//const BASE_URL = 'http://25.62.90.178:8082';
////local
//const BASE_URL = 'http://localhost:44447';
//const BASE_URL = 'http://192.168.1.3:8085';
//const BASE_URL = 'http://192.168.1.3:8078';
//const BASE_URL = 'http://soledad.cuvitekomni.com';
const BASE_URL = 'http://surtiabarrotes.cuvitekomni.com';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});


import { useState } from 'react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/DeviceTypes/Add';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { AddAsync, SetAxiosPrivate, CalculateVolume } from '../../../Functions/Catalogs/DeviceTypes/DeviceTypeStore';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { Pivot, PivotItem } from '@fluentui/react';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniDropdown } from '../../Shared/Controls/Dropdown/OmniDropdown';
import { TypeOfContent } from '../../../Assets/Constants/Enums/TypeOfContent';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';

export default function Add() {
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [deviceType, setDeviceType] = useState(
        {
            heightCentimeters: 0,
            widthCentimeters: 0,
            depthCentimeters: 0,
            kilos: 0,
        }
    );
    const [openCallout, setOpenCallout] = useState();
    const [showErrors, setShowErrors] = useState({
        description: undefined,

    });
    const submit = async () => {
        setOpenCallout(true);
    };
    const Save = async () => {
        let errorMessages = [];
        var data = deviceType;        
        if (data) {
            if (!data?.description) {
                errorMessages.push("La descripción es obligatoria");
            }
            if (!data?.deviceTypeClue) {
                errorMessages.push("La clave es obligatoria");
            }
            if (!data.heightCentimeters) {
                errorMessages.push("El campo alto (cm) es obligatorio");
            }
            if (!data.widthCentimeters) {
                errorMessages.push("El campo ancho (cm) es obligatorio");
            }
            if (!data.depthCentimeters) {
                errorMessages.push("El campo fondo (cm) es obligatorio");
            }
            if (!data.kilos) {
                errorMessages.push("El campo capacidad de carga en kilos es obligatorio");
            }
            if (!data.typeOfContent) {
                errorMessages.push("El campo tipo de contenido es obligatorio");
            }
            if (data.requireDeviceKey)
            {
                if (!data.numberOfDevices)
                    errorMessages.push("El campo IDs es obligatorio");
                else if (data?.numberOfDevices == '0')
                    errorMessages.push("El campo IDs debe ser mayor a 0");
                else
                {
                    data.numberOfDevices = data.requireDeviceKey ? (data?.numberOfDevices) : 0;
                    if (data.numberOfDevices.includes('.')) {
                        errorMessages.push("El campo IDs no permite decimales");
                    }
                }
            }
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            data.capacityCubicCentimeters = CalculateVolume(data?.heightCentimeters, data?.widthCentimeters, data?.depthCentimeters, false)
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    const onBlurDescription = (event) => {
        ValidText(event, "description", "La descripción es obligatoria", false);
    };
    const onBlurDeviceTypeClue = (event) => {
        ValidText(event, "deviceTypeClue", "La clave es obligatoria", false);
    };
    const onBlurHeightCentimeters = (event) => {
        ValidText(event, "heightCentimeters", "El campo alto (cm) es obligatorio", true);
    };
    const onBlurWidthCentimeters = (event) => {
        ValidText(event, "widthCentimeters", "El campo ancho (cm) es obligatorio", true);
    };
    const onBlurDepthCentimeters = (event) => {
        ValidText(event, "depthCentimeters", "El campo fondo (cm) es obligatorio", true);
    };
    const onBlurKilo = (event) => {
        ValidText(event, "kilos", "La capacidad de carga en kilos es obligatoria", true);
    };
    const onBlurNumberOfDevices = (event) => {
        ValidText(event, "numberOfDevices", "La cantidad de dispositivos es obligatoria", true);
    };
    function ValidText(event, property, text, isNumber) {
        const input = event.target.value;
        const value = isNumber ? Number(input) : input;

        setShowErrors(prevErrors => ({
            ...prevErrors,
            [property]: input ? (isNumber && value <= 0 ? 'El campo debe ser mayor a 0' : undefined) : text
        }));

        if (!input || (isNumber && value <= 0)) {
            event?.target?.focus();
        }
    }
    //function CalculateVolume(height, width, depth)
    //{
    //    var volumen = (parseFloat(height) * parseFloat(width) * parseFloat(depth)) / 1000000;
    //    return volumen.toFixed(3);
    //}

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client">
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox
                                                    required
                                                    focus
                                                    title='Descripción'
                                                    maxLength={32}
                                                    property="description"
                                                    edit={setDeviceType}
                                                    value={deviceType?.description}
                                                    blur={onBlurDescription}
                                                    validationMessage={showErrors.description}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-12'>
                                                <OmniTextBox
                                                    required
                                                    title='Clave'
                                                    maxLength={3}
                                                    property="deviceTypeClue"
                                                    edit={setDeviceType}
                                                    value={deviceType?.deviceTypeClue}
                                                    blur={onBlurDeviceTypeClue}
                                                    validationMessage={showErrors.deviceTypeClue}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-12'>
                                                <OmniTextBox
                                                    required
                                                    title='Alto (cm)'
                                                    maxLength={8}
                                                    property="heightCentimeters"
                                                    edit={setDeviceType}
                                                    value={deviceType?.heightCentimeters}
                                                    blur={onBlurHeightCentimeters}
                                                    validationMessage={showErrors.heightCentimeters}
                                                    regex={/^\d*\.?\d*$/}
                                                />
                                            </div>
                                            <div className='col-lg-3 col-md-12'>
                                                <OmniTextBox
                                                    required
                                                    title='Ancho (cm)'
                                                    maxLength={8}
                                                    property="widthCentimeters"
                                                    edit={setDeviceType}
                                                    value={deviceType?.widthCentimeters}
                                                    blur={onBlurWidthCentimeters}
                                                    validationMessage={showErrors.widthCentimeters}
                                                    regex={/^\d*\.?\d*$/}
                                                />
                                            </div>
                                            <div className='col-lg-3 col-md-12'>
                                                <OmniTextBox
                                                    required
                                                    title='Fondo (cm)'
                                                    maxLength={8}
                                                    property="depthCentimeters"
                                                    edit={setDeviceType}
                                                    value={deviceType?.depthCentimeters}
                                                    blur={onBlurDepthCentimeters}
                                                    validationMessage={showErrors.depthCentimeters}
                                                    regex={/^\d*\.?\d*$/}
                                                />
                                            </div>
                                            <div className='col-lg-3 col-md-12'>
                                                <OmniLabel
                                                    className='colorLabelContentBlue'
                                                    text='Capacidad mt³'
                                                    value={
                                                        CalculateVolume(
                                                            deviceType?.heightCentimeters,
                                                            deviceType?.widthCentimeters,
                                                            deviceType?.depthCentimeters,
                                                            true
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-8 col-md-12'>
                                                <OmniTextBox
                                                    required
                                                    title='Capacidad de carga en kilos'
                                                    maxLength={8}
                                                    property="kilos"
                                                    edit={setDeviceType}
                                                    value={deviceType?.kilos}
                                                    blur={onBlurKilo}
                                                    validationMessage={showErrors.kilos}
                                                    regex={/^\d*\.?\d*$/}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniDropdown
                                                    title='Tipo de contenido'
                                                    items={TypeOfContent}
                                                    property="typeOfContent"
                                                    name="typeOfContentDescription"
                                                    edit={setDeviceType}
                                                    required
                                                    value={deviceType?.typeOfContentDescription}
                                                    selected={deviceType?.typeOfContent}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCheckBox
                                                    title='Pide ID del dispositivo'
                                                    propertyId="requireDeviceKey"                                                   
                                                    edit={setDeviceType}
                                                    propertyChecked={deviceType?.requireDeviceKey}
                                                />
                                            </div>
                                        </div> 
                                    </OmniCard>
                                </div>
                                {
                                    deviceType?.requireDeviceKey &&
                                    <div className='col-md-auto'>
                                        <OmniCard title='Cantidad de dispositivos'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox
                                                        required
                                                        focus
                                                        title='IDs'
                                                        inputType="number"
                                                        maxLength={4}
                                                        property="numberOfDevices"
                                                        edit={setDeviceType}
                                                        value={deviceType?.numberOfDevices}
                                                        blur={onBlurNumberOfDevices}
                                                        validationMessage={showErrors.numberOfDevices}
                                                    />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                }                               
                            </div>
                        </div>
                    </PivotItem>

                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={Save}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    );
}
import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { ReactComponent as XmlFile } from '../../../Assets/Images/Icons/XmlFile.svg';
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
export const ItemsStatus = [
    {
        key: "2",
        value: "2",
        description: "Por validar",
        className: "color-danger-fill"
    },
    {
        key: "3",
        value: "3",
        description: "Validado",
        className: "color-info-fill"
    },
    {
        key: "4",
        value: "4",
        description: "Omitido",
        className: "color-purple-fill"
    }
];
export const OverlayDrawerStatusXml = (props) => {
 
   
    const onChangeCheckBoxStatus = (event, data) => {
        if (data.checked) {
            props.setParam((prev) => ({
                ...prev,
                status: [...prev.status, ItemsStatus.find(x => x.description === event.target.name)],
            }));
        }
        else {
            props.setParam((prev) => ({
                ...prev,
                status: prev.status.filter(item => item.description !== event.target.name),
            }));
        }
    };
    const addStatus = () => {
        props.setShowAttachStatusPanel(false);
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachStatusPanel(false);
        props.setParam(prevEdit => ({ ...prevEdit, status: [] }));
    };
    return (
        <OverlayDrawer position="end" open={props.showAttachStatusPanel} modalType="alert"
            style={{ width: '448px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Estatus
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniLabel text='Seleccione los estatus' />
                    </div>
                </div>
                    <div className='row mt-2'>
                        <div className='col-md-12'>
                            <div className='row overflow-panel'>
                            <div className='col-md-12'>
                                {ItemsStatus.map((item) => (
                                    <OmniCheckBox key={item.key} title={<div className={`padding-checkbox ${item?.className ?? ''} `}><XmlFile className="margin-checkbox-img" /> {item.description}</div>} inactivefieldEdited onChange={onChangeCheckBoxStatus} name={item.description} propertyChecked={props.param.status.some(x => x.value === item.value)} />
                                ))
                                }
                                </div>
                            </div>
                        </div>
                    </div>
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addStatus}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}
import {
    leftButtons,
    rightButtons,
    rightButtonsSecond,
    rightButtonsDisabled,
    rightButtonsSecondDisabled,
    rightButtonsExec
} from '../../../Assets/Constants/BarButtons/Accounting/XMLFile/XMLFile';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarButtons from '../../Shared/BarButtons/BarButtons';
import '../../../Assets/Styles/Accounting/Accounting.css';
import { useState, useEffect } from 'react';
import {
    SetAxiosPrivate, GetDate, DateDefaultDisabled, DateDefault, GetByFilterAsync, FormatMoney,
    LastDate, GetLastDate, ToShortDateNow, GetFileXmlByIdAsync, UpdateStatusByIdAsync, GetFileZipByIdAsync
} from '../../../Functions/Accounting/XMLFile/XMLFileStore';
import {
    SetAxiosPrivate as SetAxiosPrivateSupplier, GetByActiveAsync
} from '../../../Functions/Catalogs/Suppliers/SupplierStore';
import { DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { OverlayDrawerDate } from '../../Shared/OverlayDrawer/OverlayDrawerDate';
import { OverlayDrawerSupplier } from '../../Shared/OverlayDrawer/OverlayDrawerSupplier';
import { OverlayDrawerStatusXml, ItemsStatus } from '../../Shared/OverlayDrawer/OverlayDrawerStatusXml';
import { TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout, Popover,
    PopoverSurface,
    PopoverTrigger
} from "@fluentui/react-components";
import { ReactComponent as XmlFile } from '../../../Assets/Images/Icons/XmlFile.svg';
import { ReactComponent as Download } from '../../../Assets/Images/Icons/Download.svg';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import * as XLSX from 'xlsx';
export default function XMLFiles() {
    const columnsHeader = [
        { columnKey: "1", label: "Documento", width: '12%' },
        { columnKey: "2", label: "RFC", width: '12%' },
        { columnKey: "3", label: "Fecha", width: '12%' },
        { columnKey: "4", label: "Subtotal", width: '12%' },
        { columnKey: "5", label: "IVA", width: '12%' },
        { columnKey: "6", label: "IEPS", width: '12%' },
        { columnKey: "7", label: "Total", width: '16%' },
        { columnKey: "8", label: " ", width: '12%' },
    ];
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateSupplier(axiosPrivate);
    const [messages, setMessages] = useState([]);
    const [showAttachSupplierPanel, setShowAttachSupplierPanel] = useState(false);
    const [showAttachStatusPanel, setShowAttachStatusPanel] = useState(false);
    const [showAttachDatePanel, setShowAttachDatePanel] = useState(false);
    const [policylist, setPolicylist] = useState();
    const [lastDate, setlastDate] = useState();
    const [contract, setContract] = useState(true);
    const [supplierList, setSupplierList] = useState([]);
    const [param, setParam] = useState({
        startDate: GetDate().startDate,
        endDate: GetDate().endDate,
        supplierType: "1",
        status: []
    });
    const handleClickNewQuery = () => {
        setParam({
            startDate: GetDate().startDate,
            endDate: GetDate().endDate,
            supplierType: "1",
            status: []
        });
        setSupplierList(supplierList.map((supplier) => ({
            ...supplier,
            selected: false,
        })));
        setPolicylist(undefined);
    }
    const HandleClickContract = () => {
        setContract(!contract)
    }

    const UpdateButtons = () => {
        if (policylist && policylist.length > 0) {
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtonsExec(() =>
                    handleClickExcel(policylist), () => handleClickDownload(policylist), handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
            }));
        }
        else {
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtons(handleClickTrigger, handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
            }));
        }
    }
    const handleClickTrigger = async () => {
        setButtons(prevEdit => ({
            ...prevEdit,
            rightButtons: rightButtonsDisabled(handleClickTrigger, LastDate(lastDate), contract, HandleClickContract)
        }));
        if (param.startDate.setHours(0, 0, 0, 0) > param.endDate.setHours(0, 0, 0, 0)) {
                setMessages([new DangerMessage("La fecha inicial no puede ser mayor a la fecha final")]);
        }
        else {
            let result = await GetByFilterAsync(param, supplierList.filter(x => x.selected).map(x => x.rfc), param.status.map(x => x.value));
            if (result.isSuccessful) {
                if (result.data && result.data.length > 0) {
                    setPolicylist(result.data);
                    setButtonsSecond({
                        rightButtons: rightButtonsSecondDisabled(
                            DateDefaultDisabled(param),
                            supplierList.filter(x => x.selected).map(x => x.name),
                            param.status.map(x => x.description))
                    });
                    setButtons(prevEdit => ({
                        ...prevEdit,
                        rightButtons: rightButtonsExec(() =>
                            handleClickExcel(result.data), () => handleClickDownload(result.data), handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
                    }));
                }
                else {
                    setMessages([new DangerMessage("No se encontraron resultados")]);
                    UpdateButtons();
                }
            }
            else {
                setMessages([new DangerMessage(result.errorMessage)]);
                UpdateButtons();
            }
        }
    }
    const [buttons, setButtons] = useState({
        leftButtons: leftButtons(),
        rightButtons: []
    });
    const [buttonsSecond, setButtonsSecond] = useState({
        rightButtons: [] });
    const GetSuppliersByStatusActive = async () => {
        let result = await GetByActiveAsync();
        if (result.isSuccessful) {
            setSupplierList(result.data);
        }
        else {
            setMessages([DangerMessage(result.errorMessage)]);
        }
    }
    const GetLastDateUpdate = async () => {
        let result = await GetLastDate();
        if (result.isSuccessful) {
            setlastDate(result.data);
        }
    }
    useEffect(() => {
        GetSuppliersByStatusActive();
        GetLastDateUpdate()
    }, [])
    const UpdateButtonsSecond = () => {
        setButtonsSecond({
            rightButtons: rightButtonsSecond(
                () => {
                    setShowAttachDatePanel(true);
                }, () => {
                    setShowAttachSupplierPanel(true);
                },
                () => {
                    setShowAttachStatusPanel(true);
                },
                DateDefault(param),
                supplierList.filter(x => x.selected).map(x => x.name),
                param.status.map(x => x.description))
        });
    }
    useEffect(() => {
        UpdateButtons();
    }, [contract]);
    useEffect(() => {
        UpdateButtons();
        UpdateButtonsSecond();
        setPolicylist(undefined);
    }, [param]);
    useEffect(() => {
        if (lastDate) {
            UpdateButtons();
        }
    }, [lastDate]);
    useEffect(() => {
        if (supplierList) {
            UpdateButtonsSecond();
            UpdateButtons();
        }
    }, [supplierList]);
    const handleClickDownload = async (data) => {
        let result = await GetFileZipByIdAsync(data.map(x => x.fileId));
        if (result && result.data) {
            const urlImagen = URL.createObjectURL(result.data);
            const link = document.createElement("a");
            link.download = `Archivos XML ${ToShortDateNow()}.zip`;
            link.href = urlImagen;
            link.click();
        }
        else {
            setMessages([new DangerMessage("No se encontraron resultados")]);
        }
    }
    const handleClickExcel = (data) => {
        const modifiedData = data.map(item => ({
            document: ItemsStatus.find(x => x.value == item.statusControl)?.description,
            rfc: item.rfc,
            date: item.date,
            amount: item.amount,
            ivaAmount: item.ivaAmount,
            iepsAmount: item.iepsAmount,
            netAmount: item.netAmount
        }));
        let Heading = [['Documento', 'RFC', 'Fecha', 'Subtotal', 'IVA', 'IEPS', 'TOTAL']];
        const wb = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, Heading);
        XLSX.utils.sheet_add_json(ws, modifiedData , { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        XLSX.writeFile(wb, `Archivos XML ${ToShortDateNow()}.xlsx`);
    }

    const handleClickUpdateXml = async (id) => {
        let result = await UpdateStatusByIdAsync(id);
        let data = [];
        if (result.isSuccessful) {
            setPolicylist((prev) => {
                const updated = prev.map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            statusControl: 4
                        };
                    }
                    return item;
                });
                data = updated;
                return updated;
            });
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtonsExec(() =>
                    handleClickExcel(data), () => handleClickDownload(data), handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
            }));
        }
        else {
            setMessages([DangerMessage(result.errorMessage)]);
        }

    }
    //useEffect(() => {
    //    if (policylist && policylist.length > 0) {
    //        setButtons(prevEdit => ({
    //            ...prevEdit,
    //            rightButtons: rightButtonsExec(() =>
    //                handleClickExcel(policylist), () => handleClickDownload(policylist), handleClickNewQuery, LastDate(lastDate), contract, HandleClickContract)
    //        }));
    //    }
    //}, [policylist])
    const PopoverRender = (content, className, item) => {
        if (item.statusControl === 2) {
            return <Popover size="small" positioning="below">
                <PopoverTrigger >
                    <TableCell>
                        <TableCellLayout className={className} >
                            {content}
                        </TableCellLayout>
                    </TableCell>

                </PopoverTrigger>
                <PopoverSurface tabIndex={-1} className="popover" onClick={() => handleClickUpdateXml(item.id)}>
                    <OmniLabel text={'Omitir XML'} />
                </PopoverSurface>
            </Popover>
        }
        else {
            return <TableCell>
                <TableCellLayout className={className} >
                    {content}
                </TableCellLayout>
            </TableCell>
        }
    }
    const handleClickDownloadXml = async (id) => {
        let result = await GetFileXmlByIdAsync(id);
        if (result && result.data) {
            const urlImagen = URL.createObjectURL(result.data);
            const link = document.createElement("a");
            link.download = `Póliza ${ToShortDateNow()}.xml`;
            link.href = urlImagen;
            link.click();
        }
        else {
            setMessages([new DangerMessage("No se encontraron resultados")]);
        }

    }
    const renderPolicyRow = (item, index) => {
        const tableRow = (
            <TableRow key={index}>
                {PopoverRender(<XmlFile />, `justify-content-center ${ItemsStatus.find(x => x.value == item.statusControl).className}`, item)}
                {PopoverRender(item.rfc, "justify-content-center", item)}
                {PopoverRender(item.date, "justify-content-center", item)}
                {PopoverRender(FormatMoney(item.amount), "justify-content-end", item)}
                {PopoverRender(FormatMoney(item.ivaAmount), "justify-content-end", item)}
                {PopoverRender(FormatMoney(item.iepsAmount), "justify-content-end", item)}
                {PopoverRender(FormatMoney(item.netAmount), "justify-content-end", item)}
                <TableCell onClick={() => handleClickDownloadXml(item.blobStorageId)}>
                    <TableCellLayout className={`justify-content-center color-secondary-fill`}>
                        <Download />
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        );
        return tableRow;
    };
    return (
        <>
            <BarButtons rightButtons={buttons.rightButtons}
                leftButtons={buttons.leftButtons} className="background-color-light-hover border-gray"
                leftButtonsClassName="col-lg-4 col-md-4" rightButtonsClassName="col-lg-8 col-md-8"         />
            <BarButtons rightButtons={buttonsSecond.rightButtons} className="background-color-light-hover barButtons"/>
            <BarMessageList messages={messages} />
            <div className='container-fluid container-scroll'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 '>
                        {policylist &&
                            <div className="updater-table-container">
                                <Table size="small" className="table table-hover table-updater">
                                    <TableHeader>
                                        <TableRow>
                                            {columnsHeader.map((column) => (
                                                <TableHeaderCell key={column.columnKey}
                                                    style={{ width: column.width }}
                                                    className={'detail-table-header-title ' + column?.classAdd}>
                                                    {column.label}
                                                </TableHeaderCell>
                                            ))}
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {
                                            policylist.map(renderPolicyRow)
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <OverlayDrawerDate showAttachDatePanel={showAttachDatePanel}
                setShowAttachDatePanel={setShowAttachDatePanel}
                setParam={setParam}
                param={param} />
            <OverlayDrawerSupplier showAttachSupplierPanel={showAttachSupplierPanel}
                setShowAttachSupplierPanel={setShowAttachSupplierPanel}
                setParam={setParam}
                param={param}
                supplierList={supplierList}
                setSupplierList={setSupplierList}/>
            <OverlayDrawerStatusXml showAttachStatusPanel={showAttachStatusPanel}
                setShowAttachStatusPanel={setShowAttachStatusPanel}
                setParam={setParam}
                param={param} />
        </>
    )
}
import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/IepsTax/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/IepsTax/IepsStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import IepsAuthorizations from '../../../Assets/Constants/Authorizations/Ieps';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey, setChangePivotKey, changePivotKey } = useGeneralStatesContext();
    const { useAccounting } = useAccountingConfigurationContext();
    const [ieps, setIeps] = useState([]);
    const [buttonsIeps, setButtonsIeps] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
   

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setIeps({
                id: result.data.id,
                description: result.data.description,
                value: result.data.value,
                transferAccountingAccount: result.data.transferAccountingAccount,
                creditableAccountingAccount: result.data.creditableAccountingAccount,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !ieps.active);
        if (result.isSuccessful) {
            setIeps((prev) => {
                return {
                    ...prev,
                    active: !ieps.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        let updatedButtons = leftButtons;
        if (!useAccounting) {
            updatedButtons = updatedButtons.filter(button => button.key !== 'BtnEdit');
        }
        updatedButtons = updatedButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = ieps.active ? "Inactivar" : "Activar";
                let statusiconbutton = ieps.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = ieps.active ? IepsAuthorizations.Delete : IepsAuthorizations.Reactive;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el IEPS?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !ieps.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (ieps) {
            setButtonsIeps(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...ieps };
            data.name = ieps.warwhouseName;
            updateItemStatus({ ...data, acronym: localStorage.getItem("userName") });
        }
    }, [ieps?.active]);

    useEffect(() => {
        if (ieps) {
            setButtonsIeps(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus, id]);   
    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    useEffect(() => {
        if (ieps) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);

    return (
        <>
            {ieps.length !== 0 &&
                (<BarButtons rightButtons={buttonsIeps.rightButtons}
                leftButtons={buttonsIeps.leftButtons} />)}
            <Pivot className="pivot-center-client" selectedKey={selectedKey}
                onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                overflowBehavior="menu" styles={pivotStyles} >
                <PivotItem headerText="Información" itemKey="information">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Valor' value={ieps.value} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Descripcion' value={ieps.description} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                {
                    useAccounting &&
                    <PivotItem headerText="Contabilidad" itemKey="contabilidad">
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Contabilidad'>
                                            <div className='row'>
                                                <OmniLabel text='Cuenta contable de ventas ' value={ieps.transferAccountingAccount} />
                                            </div>
                                            <div className='row'>
                                                <OmniLabel text='Cuenta contable de compras' value={ieps.creditableAccountingAccount} />
                                            </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                }
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={ieps} />
                </PivotItem>
            </Pivot>
        </>
    )
}
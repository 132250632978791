import { useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/IepsTax/Add';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/IepsTax/IepsStore';
import { GetToIepsAutocomplete, SetAxiosPrivate as SetAxiosPrivateUsers} from '../../../Functions/SatCatalogs/RateOrFees/RateOrFeeStore'
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Ieps.svg').default
}
export default function Add() {
    const {handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const { useAccounting } = useAccountingConfigurationContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const[ieps, setIeps]=useState();
    const [rateOrFees,setRateOrFees]=useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [userLoad, setUserLoad] = useState();
    const [serchClient, setSerchClientr] = useState('');
    const [showErrors, setShowErrors] = useState(
        {
            transferAccountingAccount: undefined,
            creditableAccountingAccount: undefined,
        });
    SetAxiosPrivateUsers(axiosPrivate);
    

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        let errorMessages = [];
        if (ieps === undefined || ieps.value === undefined) {
            errorMessages.push("El valor es obligatorio");
        }
        if (useAccounting)
        {
            if (!ieps.transferAccountingAccount ) {
                errorMessages.push("El campo cuenta contable de ventas es obligatorio");
            }
            if (!ieps.creditableAccountingAccount) {
                errorMessages.push("El campo cuenta contable de compras es obligatorio");
            }
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            if (useAccounting) {
                ieps.transferAccountingAccount = ieps.transferAccountingAccount.endsWith(".") ? ieps.transferAccountingAccount.slice(0, -1) : ieps.transferAccountingAccount;
                ieps.creditableAccountingAccount = ieps.creditableAccountingAccount.endsWith(".") ? ieps.creditableAccountingAccount.slice(0, -1) : ieps.creditableAccountingAccount;
            }
            var data = ieps;
            let result = await AddAsync(data, useForeignKey);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                data.name=ieps.value;
                addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    const GetRateOrFees = async()=>{
        let result = await GetToIepsAutocomplete();
        if (result.isSuccessful) {
            var rateOrFees= result.data.map((item) => ({
                key: item.id ?? item.maximumRateOrFee,
                value: (item.maximumRateOrFee*100).toFixed(2) .toString(),
                text: (item.maximumRateOrFee*100).toFixed(2).toString()
            }));
            setRateOrFees(rateOrFees);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const onChangeItemRateOrFee = async (id, ieps) => {
        setIeps(prevEedit => ({
            ...prevEedit,
            value: id,
            ieps: ieps,
            description: 'IEPS ' + ieps, 
        }));
    }
    const OnChangeRateOrFeeText = async (text) => {
        if (userLoad) {
            ChangeText(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }
    function ChangeText(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = userLoad.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.text.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                setRateOrFees(filteredData);
            }
        }
    }

    const onBlurTransferAccountingAccount = (event) => {
        ValidText(event, "transferAccountingAccount", "cuenta contable de ventas");
    }
    const onBlurCreditableAccountingAccount = (event) => {
        ValidText(event, "creditableAccountingAccount", "cuenta contable de compras");
    }
    function ValidText(event, property, fild) {
        let text = event.target.value.trim();
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }

    useEffect(()=>{
        GetRateOrFees();
    },[])
        
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información">
                        <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>                                   
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniCombobox
                                                    title='Valor'
                                                    items={rateOrFees}
                                                    selected={ieps?.value}
                                                    value={ieps?.value}
                                                    onChangeItem={onChangeItemRateOrFee} required 
                                                    onChangeItemText={OnChangeRateOrFeeText} 
                                                    image={iconCatalog}
                                                />
                                        </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox
                                                    title='Descripcion'
                                                    maxLength={16}
                                                    property="description"
                                                    edit={setIeps}
                                                    value={ieps?.description}                                                    
                                                />
                                            </div>
                                        </div>
                                </OmniCard>
                            </div>
                        </div>
                        </div>
                    </PivotItem>
                    {
                        useAccounting &&
                        <PivotItem headerText='Contabilidad'>
                                <div className='container-fluid container-scroll'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                            <OmniCard title='Cuentas contables'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <div className='row'>
                                                        <OmniTextBox
                                                            focus
                                                            required
                                                            title='Cuenta contable de ventas'
                                                            maxLength={16}
                                                            regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                            property="transferAccountingAccount"
                                                            edit={setIeps}
                                                            value={ieps?.transferAccountingAccount}
                                                            blur={onBlurTransferAccountingAccount}
                                                            validationMessage={showErrors.transferAccountingAccount}
                                                        />
                                                    </div>
                                                    <div className='row'>
                                                        <OmniTextBox
                                                            required
                                                            title='Cuenta contable de compras'
                                                            maxLength={16}
                                                            regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                            property="creditableAccountingAccount"
                                                            edit={setIeps}
                                                            value={ieps?.creditableAccountingAccount}
                                                            blur={onBlurCreditableAccountingAccount}
                                                            validationMessage={showErrors.creditableAccountingAccount}
                                                        />
                                                    </div>
                                                </div>
                                            </OmniCard>
                                        </div>
                                    </div>
                                </div>
                        </PivotItem>
                    }
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            <OmniCallout
                open={openCallout}
                updateIsOpenAlert={setOpenCallout}
                leftFunction={AddConfirm}
                title='Confirmación'
                content='¿Desea guardar los cambios?'
                leftButton={'Si'}
                rightButton={'Regresar'}
                backgroundColorLeft={'var(--success)'}
                colorLeft={'white'}
                backgroundColorRight={'var(--secondary)'}
                colorRight={'white'}
                buttonId={'BtnGuardar'}
                barGroupKey={'rigthCommandBar'}
            />
        </>
    )
}
